'use client';

import {PopUpObject} from '@/types/objects/popup';
import {useEffect, useState} from 'react';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import Link from 'next/link';
import CloseIcon from '@/images/icons/close.svg';

interface PopUpProps {
    popUp: PopUpObject;
}

export default function PopUp({popUp}: PopUpProps) {
    const [showPopUp, setShowPopUp] = useState<boolean>(false);

    useEffect(() => {
        const popUpShown = localStorage.getItem('popUpShown');

        if (!popUpShown) {
            setTimeout(() => {
                setShowPopUp(true);
                document.body.style.overflow = 'hidden';
            }, 2000);
        }
    }, []);

    const handlePopUpClose = () => {
        setShowPopUp(false);
        localStorage.setItem('popUpShown', 'true');
        document.body.style.overflow = 'auto';
    }

    if (!showPopUp) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 w-full h-full">
            <div className="fixed inset-0 opacity-90 bg-gradient-to-r from-gradients-blueFrom to-gradients-blueTo"
                 onClick={() => handlePopUpClose()}></div>
            <div className="bg-white relative max-w-112 w-full max-h-full overflow-y-auto rounded-xl">
                {popUp.image?.path ? (
                    <div className="aspect-[16/9] relative overflow-hidden">
                        <PimcoreImage image={popUp.image} className="w-full h-full absolute inset-0 object-cover object-center"/>
                    </div>
                ) : <></>}
                <div className={`px-6 pb-6 ${popUp?.image?.path ? 'pt-6' : 'pt-14'}`}>
                    {popUp.title ? (
                        <h5>{popUp.title}</h5>
                    ) : <></>}
                    {popUp.description ? (
                        <p className="mb-0">{popUp.description}</p>
                    ) : <></>}
                    {popUp.link?.path ? (
                        <Link href={popUp.link.path}
                              onClick={() => handlePopUpClose()}
                              className="btn btn-primary mt-6 mb-0"
                              title={popUp.link.title || ''}>
                            {popUp.link.text}
                        </Link>
                    ) : <></>}
                </div>
                <div className="absolute right-4 top-4 cursor-pointer"
                     onClick={() => handlePopUpClose()}>
                    <CloseIcon className={`w-6 h-6 ${popUp.image?.path ? 'text-white' : 'text-blue-200'}`}/>
                </div>
            </div>
        </div>
    )
}