'use client';

import React, {useEffect, useRef, useState} from 'react';
import {PimcoreImage as PimcoreImageType, PimcoreVideoThumbnail} from '@/types/pimcore';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import {useBreakpoint} from '@/hooks/breakpoint';

interface HeroHeaderVideoProps {
    videoThumbnail: PimcoreVideoThumbnail | null,
    videoThumbnailMobile: PimcoreVideoThumbnail | null,
    fallbackImage: PimcoreImageType | null
}

export default function HeroHeaderVideo({videoThumbnail, videoThumbnailMobile, fallbackImage}: HeroHeaderVideoProps) {
    return (
        <div className="relative h-full w-full transition duration-300">
            {videoThumbnail?.status === 'finished' && videoThumbnail?.formats?.mp4 ? (
                <video
                    className={`h-full absolute w-full object-cover ${videoThumbnailMobile?.status === 'finished' ? 'hidden md:block' : ''}`}
                    muted
                    autoPlay
                    playsInline
                    loop
                    preload={'none'}>
                    <source src={videoThumbnail.formats.mp4} type="video/mp4"/>
                </video>
            ) : <></>}
            {videoThumbnailMobile?.status === 'finished' && videoThumbnailMobile?.formats?.mp4 ? (
                <video
                    className={`h-full absolute w-full object-cover ${videoThumbnail?.status === 'finished' ? 'block md:hidden' : ''}`}
                    muted
                    autoPlay
                    playsInline
                    loop
                    preload={'none'}>
                    <source src={videoThumbnailMobile.formats.mp4} type="video/mp4"/>
                </video>
            ) : fallbackImage && (
                <PimcoreImage className="h-full absolute w-full object-cover"
                              image={fallbackImage}/>
            )}
        </div>
    );
}
